import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CTA = makeShortcode("CTA");
const List = makeShortcode("List");
const Process = makeShortcode("Process");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Voting Wallet Setup`}</h1>
    <h2>{`Learn how to set up your digital wallet or wallets to vote on MakerDAO governance`}</h2>
    <p>{`Maker (MKR) token holders may set up their Voting Contract with a single hot or cold wallet, or, as a linked wallet.`}</p>
    <p>{`The linked wallet provides a more secure setup enabling users to vote with a hot wallet while their MKR remains stored on a cold wallet.`}</p>
    <h2>{`Linked-Wallet Setup`}</h2>
    <p>{`The Linked Wallet Voting Contract can be set up using hardware wallets like Trezor or Ledger, paper wallets using SETH, or two MetaMask Accounts.`}</p>
    <p>{`Setting up a Voting Contract is a one-time action as long as the same wallet or wallets are used in voting.`}</p>
    <p>{`This guide uses Trezor as the cold wallet and MetaMask as the hot wallet. The process is similar for other cold wallets.`}</p>
    <CTA mdxType="CTA">
      <p>{`See our guides to using other wallet types:`}</p>
      <List mdxType="List">
        <ul>
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "https://web.archive.org/web/20190112110248/https://medium.com/makerdao/how-to-setup-the-voting-contract-using-a-ledger-hardware-wallet-1f00d49ce43a"
              }}>{`Linked Wallet Setup: Ledger Nano S`}</a></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "https://web.archive.org/web/20190112110247/https://medium.com/makerdao/how-to-setup-the-voting-contract-using-two-metamask-accounts-4e83e4fec602"
              }}>{`Linked Wallet Setup: Two MetaMask Accounts`}</a></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "https://web.archive.org/web/20180913234419/https://medium.com/makerdao/guide-vote-proxy-setup-with-seth-f62397a10c59"
              }}>{`Linked Wallet Setup: Paper Wallets Using SETH`}</a></p>
          </li>
        </ul>
      </List>
    </CTA>
    <h3>{`Setup with Trezor + MetaMask`}</h3>
    <p>{`Please have the Trezor wallet ready and the MetaMask browser extension installed and running. Log in and make sure the appropriate MetaMask account is selected.`}</p>
    <p>{`Navigate to the `}<a parentName="p" {...{
        "href": "https://vote.makerdao.com/"
      }}>{`Governance Portal`}</a>{`. Before setting up the contract, connect Trezor and MetaMask.`}</p>
    <Process mdxType="Process">
      <Box mdxType="Box">
        <h4>{`Connect MetaMask`}</h4>
        <p>{`Find the `}<a parentName="p" {...{
            "href": "https://vote.makerdao.com/"
          }}>{`“Accounts”`}</a>{` drop-down menu on the right side of the top banner. If MetaMask is active, the current account will show as an available wallet. Make sure this is the correct account and select it to use as the hot wallet.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-2.png",
            "alt": "Select MetaMask Account"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Connect Trezor`}</h4>
        <p>{`In the `}<a parentName="p" {...{
            "href": "https://vote.makerdao.com/"
          }}>{`“Accounts”`}</a>{` drop-down menu, select “Connect to Trezor.” The Trezor wallet must be plugged in for it to show up. Follow the steps to enter the Trezor’s pin and passcode. When prompted, select an address with MKR to use as the cold wallet. Click “Unlock Wallet.”`}</p>
        <p><img parentName="p" {...{
            "src": "https://firebasestorage.googleapis.com/v0/b/gitbook-28427.appspot.com/o/assets%2F-LfMBoUuZIjWKAx3JRdc%2F-M-S38P9VasfOu2vZpKb%2F-M-S3BZghPnH23EGTDvu%2F4.png?generation=1581035607356870&alt=media",
            "alt": "Export Accounts"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "https://firebasestorage.googleapis.com/v0/b/gitbook-28427.appspot.com/o/assets%2F-LfMBoUuZIjWKAx3JRdc%2F-M-S38P9VasfOu2vZpKb%2F-M-S3BZirOJkwBvUo4Jc%2F5.png?generation=1581035637786497&alt=media",
            "alt": "Select Address"
          }}></img></p>
        <p>{`The `}<a parentName="p" {...{
            "href": "https://vote.makerdao.com/"
          }}>{`“Accounts”`}</a>{` drop-down menu will now show that both MetaMask and Trezor are connected.`}</p>
        <p><img parentName="p" {...{
            "src": "https://firebasestorage.googleapis.com/v0/b/gitbook-28427.appspot.com/o/assets%2F-LfMBoUuZIjWKAx3JRdc%2F-M-S38P9VasfOu2vZpKb%2F-M-S3BZmNPwBIdRjfIz1%2F7.png?generation=1581035610670091&alt=media",
            "alt": "Accounts"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Agree to Terms-of-Use`}</h4>
        <p>{`Select “Vote with a Linked Wallet” from the menu. Read and agree to the “Terms of Use” on the following page.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-8.png",
            "alt": "Selecting a Voting Contract type"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-9.png",
            "alt": "Terms of Use"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Confirm Voting Wallet`}</h4>
        <p>{`On the following page, select MetaMask as the voting wallet. Click “Connect” to continue and “Confirm Voting Wallet” on the next page.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-10.png",
            "alt": "Select voting wallet"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-11.png",
            "alt": "Confirm voting wallet"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Select a cold wallet`}</h4>
        <p>{`In this case, select the Trezor. Follow the steps to enter the Trezor’s pin and passcode.`}</p>
        <p>{`When prompted, choose an address with MKR. Click “Confirm Wallet.”`}</p>
        <p><em parentName="p">{`Note: The above step might be skipped if a cold wallet was already selected in the wallet drop-down menu.`}</em></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-12.png",
            "alt": "Select a cold wallet"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-13.png",
            "alt": "Select MKR balance"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Enable hot wallet voting`}</h4>
        <p>{`Now that the hot and cold wallets are selected, the following step initiates the link to enable hot wallet voting with MKR stored on the cold wallet. Click “Link Wallets.”`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-14.png",
            "alt": "Link hot and cold wallets"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Sign transactions`}</h4>
        <p>{`Sign transactions on Trezor and MetaMask to confirm the link. Click “Next” each time to continue and follow prompts to approve the transaction.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-15.png",
            "alt": "Sign Trezor transaction"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-16.png",
            "alt": "Sign MetaMask transaction"
          }}></img></p>
        <p>{`Grant the hot wallet permission to vote with the MKR stored on the cold wallet. Click “Next” and approve the transaction on Trezor.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-17.png",
            "alt": "Grant hot wallet permissions"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-18.png",
            "alt": "Grant hot wallet permissions"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Grant contract transfer allowances`}</h4>
        <p>{`Now, grant the contract transfer allowances for MKR tokens. Click “Next” and confirm the transaction on Trezor.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-19.png",
            "alt": "Grant permissions"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-20.png",
            "alt": "Grant permissions"
          }}></img></p>
        <p>{`Once the transaction is confirmed, grant the contract transfer allowances for IOU tokens. Click “Next” and confirm the transaction on Trezor.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-21.png",
            "alt": "Grant permissions"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-22.png",
            "alt": "Grant permissions"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Lock MKR in the voting system`}</h4>
        <p>{`The final step is to “Lock MKR” into the voting system. Users may adjust the amount of MKR in their Voting Contract later by topping-up or withdrawing at any time with a single transaction.`}</p>
        <p><em parentName="p">{`Note: See the end of this section for instructions on how to top-up or withdraw from the voting contract.`}</em></p>
        <p>{`Set how much MKR to lock in the contract and click “Confirm.” Always check to make sure the information is correct. Click “Confirm” again and approve the transaction on Trezor.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-23.png",
            "alt": "Lock MKR"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-24.png",
            "alt": "Lock MKR"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <p>{`Once the transaction is confirmed, the Voting Contract setup is complete.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-25.png",
            "alt": "Setup complete"
          }}></img></p>
        <p>{`Click “Start voting” to return to the main `}<a parentName="p" {...{
            "href": "https://vote.makerdao.com/"
          }}>{`Governance Portal`}</a>{` and to participate in Governance Polls and Executive Votes.`}</p>
        <CTA mdxType="CTA">
          <p>{`Learn more about voting in `}<a parentName="p" {...{
              "href": "/learn/governance/how-voting-works/"
            }}>{`How Voting Works`}</a></p>
        </CTA>
      </Box>
    </Process>
    <h3>{`Managing the Linked-Wallet Voting Contract`}</h3>
    <h4>{`Top-Up Voting Contract`}</h4>
    <p>{`For security reasons, locking additional MKR into the Voting Contract can only be done from the cold wallet, in this case, Trezor.`}</p>
    <Process mdxType="Process">
      <Box mdxType="Box">
        <p>{`To top-up the Voting Contract, make sure the cold wallet is plugged in. In the `}<a parentName="p" {...{
            "href": "https://vote.makerdao.com/"
          }}>{`Governance Portal`}</a>{`, select “Connect to Trezor” from the “Accounts” drop-down menu.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-26.png",
            "alt": "Connect to Trezor"
          }}></img></p>
        <p>{`Follow the steps to enter the Trezor’s pin and passcode.`}</p>
        <p>{`When prompted, select the address that is linked to the Voting Contract.`}</p>
        <p>{`Click “Unlock Wallet.”`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-27.png",
            "alt": "Select Linked Cold Wallet Address"
          }}></img></p>
        <p>{`Return to the `}<a parentName="p" {...{
            "href": "https://vote.makerdao.com/"
          }}>{`Governance Portal`}</a>{` and click “Voting Contract” to bring up the secure voting menu. Select “Top-Up Voting Contract.”`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-28.png",
            "alt": "Manage Voting Contract"
          }}></img></p>
        <p>{`Enter the MKR amount to add to the contract and click “Lock MKR.”`}</p>
        <p>{`Approve the transaction on Trezor.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-29.png",
            "alt": "Lock MKR"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Withdraw MKR from Voting Contract`}</h4>
        <p>{`Use either wallet to withdraw MKR from the Voting Contract and return the tokens to the cold wallet. For this example, the MetaMask hot wallet will withdraw the locked MKR. The process is similar when using a cold wallet.`}</p>
        <p>{`Make sure MetaMask is running, and the account associated with the contract is selected. On the `}<a parentName="p" {...{
            "href": "https://vote.makerdao.com/"
          }}>{`Governance Portal`}</a>{`, click “Voting Contract” to bring up the secure voting menu. Select “Withdraw from Voting Contract.”`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-31.png",
            "alt": "Manage Voting Contract"
          }}></img></p>
        <p>{`Enter the MKR amount to withdraw from the contract and click “Withdraw MKR.” Approve the transaction on MetaMask.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-32.png",
            "alt": "Withdraw MKR"
          }}></img></p>
        <p>{`Once the transaction is confirmed, unlocked MKR returns to the cold wallet, and the contract manager reappears. The MKR balance in the voting contract will update to reflect the changes.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-33.png",
            "alt": "Updated Voting Contract"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Break Wallet Link`}</h4>
        <p>{`The wallet link can be broken from either the cold or the hot wallet, in turn, closing the contract. In case the hot wallet is compromised, the cold wallet can safely withdraw MKR from the contract before breaking the link.`}</p>
        <p><em parentName="p">{`Note: All MKR must be withdrawn before the link can be broken. Follow the instructions above to withdraw all MKR from the contract.`}</em></p>
        <p>{`For this example, the cold wallet will break the wallet link. On the `}<a parentName="p" {...{
            "href": "https://vote.makerdao.com/"
          }}>{`Governance Portal`}</a>{`, click “Connect to Trezor” from the “Accounts” drop-down menu and connect to the Voting Contract’s cold wallet address.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-34.png",
            "alt": "Connect to Trezor"
          }}></img></p>
        <p>{`Return to the Governance Portal and click “Voting Contract” to bring up the secure voting menu. Select “Break Wallet Link.”`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-35.png",
            "alt": "Manage Voting Contract"
          }}></img></p>
        <p>{`If not done already, follow the prompt to withdraw any remaining locked MKR.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-36.png",
            "alt": "Withdraw Remaining MKR"
          }}></img></p>
        <p>{`Otherwise, click “Break Link” to close the contract. Confirm the transaction on the cold wallet.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-37.png",
            "alt": "Break Wallet Link"
          }}></img></p>
        <p>{`Once the transaction is confirmed, the link is broken, and the voting contract is closed. To vote again, return to the Governance Portal to set up a new voting contract.`}</p>
      </Box>
    </Process>
    <h2>{`Single Wallet Setup`}</h2>
    <CTA mdxType="CTA">
      <p>{`Prefer to watch a video? Check out the `}<a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=nWhLzyhZV-Q&list=PLLzkWCj8ywWP6yCPPm1IRKysNsrzg3LQ6&index=6&t=0s"
        }}>{`Single Wallet Contract Setup`}</a>{`.`}</p>
    </CTA>
    <Process mdxType="Process">
      <Box mdxType="Box">
        <h4>{`Sync MetaMask`}</h4>
        <p>{`To set up a Single Wallet Voting Contract, make sure the `}<a parentName="p" {...{
            "href": "https://metamask.io/"
          }}>{`MetaMask`}</a>{` browser extension is installed and running.`}</p>
        <p>{`Navigate to the `}<a parentName="p" {...{
            "href": "https://vote.makerdao.com/"
          }}>{`Governance Portal`}</a>{`. Find the “Accounts” drop-down menu on the right side of the top banner. Select the appropriate account to use as the contract wallet.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-38.png",
            "alt": "Select Account"
          }}></img></p>
        <p>{`Select “Set Up Now” on the highlighted section of the front-page to start the Voting Contract setup.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-40.png",
            "alt": "Set Up Now"
          }}></img></p>
        <p>{`Select “Vote with a Single Wallet” from the menu. Read and agree to the “Terms of Use” on the following page.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-41.png",
            "alt": "Select a Voting Contract Type"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-42.png",
            "alt": "Terms of Use"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Grant permissions`}</h4>
        <p>{`The next page will ask to “Grant Permissions.” This grants the contract the ability to move MKR tokens. Confirm the transaction on MetaMask.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-43.png",
            "alt": "Grant Permissions for MKR Token Transfer"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-44.png",
            "alt": "Grant Permissions for MKR Token Transfer"
          }}></img></p>
        <p>{`Click “Next” once the transaction is confirmed. The next page will grant the contract transfer allowances for IOU tokens. Confirm the transaction on MetaMask.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-45.png",
            "alt": "Grant Permissions for IOU Token Transfer"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-46.png",
            "alt": "Grant Permissions for IOU Token Transfer"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Lock MKR in the voting system`}</h4>
        <p>{`Set how much MKR this contract will hold. A contract voting with more MKR has a more significant impact on the system.`}</p>
        <p>{`Lock in more MKR to\\ the Voting Contract or withdraw locked MKR at any time in a single transaction.`}</p>
        <p>{`Note: See the end of this section for instructions on how to top-up the contract, withdraw MKR, or break the wallet contract.
Click “Confirm” and check to make sure the information is correct. “Confirm” again and approve the transaction on MetaMask.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-47.png",
            "alt": "Confirm Lock MKR"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-48.png",
            "alt": "Confirm Lock MKR"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-49.png",
            "alt": "Confirm Lock MKR"
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "/images/voter-50.png",
            "alt": "Confirm Lock MKR"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Setup complete`}</h4>
        <p>{`Once MKR is locked in the contract, the Voting Contract setup is complete.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-51.png",
            "alt": "Set Up Complete!"
          }}></img></p>
        <p>{`Click “Start Voting” to return to the main Governance Portal to participate in Governance Polls and Executive Votes.`}</p>
        <p>{`To begin voting, skip ahead to the Voting Process section.`}</p>
      </Box>
    </Process>
    <h3>{`Managing the Single-Wallet Voting Contract`}</h3>
    <Process mdxType="Process">
      <Box mdxType="Box">
        <h4>{`Top-Up Voting Contract`}</h4>
        <p>{`To add MKR to a Single Wallet Voting Contract, make sure MetaMask is running, and the account associated with the contract is selected.`}</p>
        <p>{`In the `}<a parentName="p" {...{
            "href": "https://vote.makerdao.com/"
          }}>{`Governance Portal`}</a>{`, click “Voting Contract” to bring up the secure voting menu. Select “Top-Up Voting Contract.”`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-52.png",
            "alt": "Manage Voting Contract"
          }}></img></p>
        <p>{`Enter the MKR amount to add to the contract and click “Lock MKR.” Approve the transaction on MetaMask.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-53.png",
            "alt": "Lock MKR"
          }}></img></p>
        <p>{`Once the transaction is confirmed, the contract manager will reappear. The MKR balance in the voting contract will update to reflect the changes.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-54.png",
            "alt": "Updated Voting Contract"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Withdraw MKR from Voting Contract`}</h4>
        <p>{`To withdraw MKR from a Single Wallet Voting Contract, make sure MetaMask is running, and the account associated with the contract is selected.`}</p>
        <p>{`In the `}<a parentName="p" {...{
            "href": "https://vote.makerdao.com/"
          }}>{`Governance Portal`}</a>{`, click “Voting Contract” to bring up the secure voting menu. Select “Withdraw from Voting Contract.”`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-55.png",
            "alt": "Manage Voting Contract"
          }}></img></p>
        <p>{`Enter the MKR amount to withdraw from the contract and click “Withdraw MKR.” Approve the transaction on MetaMask.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-56.png",
            "alt": "Withdraw MKR"
          }}></img></p>
        <p>{`Once the transaction is confirmed, unlocked MKR returns to the wallet, and the contract manager reappears. The MKR balance in the voting contract will update to reflect the changes.`}</p>
        <p><img parentName="p" {...{
            "src": "/images/voter-57.png",
            "alt": "Updated Voting Contract"
          }}></img></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Close Voting Contract`}</h4>
        <p>{`For a Single Wallet Voting Contract, breaking a link is not required. To close the contract, simply withdraw all MKR back to the wallet.`}</p>
        <p>{`To vote with the same wallet later, return to the Voting Contract manager to top-up locked MKR. The wallet will retain contract permissions. Repeating the setup process is not necessary.`}</p>
      </Box>
    </Process>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      